import { setRangeValue } from "tsparticles-engine";
export class Remove {
    constructor() {
        this.quantity = 2;
    }
    get particles_nb() {
        return this.quantity;
    }
    set particles_nb(value) {
        this.quantity = setRangeValue(value);
    }
    load(data) {
        if (!data) {
            return;
        }
        const quantity = data.quantity ?? data.particles_nb;
        if (quantity !== undefined) {
            this.quantity = setRangeValue(quantity);
        }
    }
}
